import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      display="block"
    >
      <path d="M8 .2l4.9 15.2L0 6h16L3.1 15.4z" />
    </svg>
  );
}

export default SvgComponent;
