import TopNavbar from "../../components/Nav/TopNavbar";
import Footer from "../../components/Sections/Footer";
import PortryLogo from "../../assets/appSpecific/portry/logo.png";
import ScanAnimation from "../../assets/appSpecific/portry/searching.gif";
import MagnifyingGlass from "../../assets/appSpecific/portry/magnifying-glass.svg";
import FreePlanSVG from "../../assets/appSpecific/portry/plan_free.svg";
import ProPlanSVG from "../../assets/appSpecific/portry/plan_pro.svg";
import CheckSVG from "../../assets/appSpecific/portry/check.svg";

import WindowsLogoSVG from "../../assets/appSpecific/portry/windows_logo.svg";
import MacLogoSVG from "../../assets/appSpecific/portry/mac_logo.svg";
import LinuxLogoSVG from "../../assets/appSpecific/portry/linux_logo.svg";

import GithubLogoSVG from "../../assets/appSpecific/portry/github_icon.svg";

import MonitoringSVG from "../../assets/appSpecific/portry/monitoring_icon.svg";
import LiveMonitoringSVG from "../../assets/appSpecific/portry/live_monitoring_icon.svg";
import FilterSVG from "../../assets/appSpecific/portry/filter_icon.svg";
import KillProcessSVG from "../../assets/appSpecific/portry/kill_process_icon.svg";

import FullButton from "../../components/Buttons/FullButton";
import styled from "styled-components";
import { Link } from "react-scroll";
import HelpUs from "../../components/Sections/HelpUs";
import { useEffect } from "react";

import ReactGA from "react-ga4";

import GithubCorner from "react-github-corner";

let freeFeatures = ["Port Scanner", "Live Monitoring", "Filtering"];
let proFeatures = [
  "Port Scanner",
  "Live Monitoring",
  "Filtering",
  "Hide Ports",
  "Remote Port Scan",
  "Report",
];

let downloads = [
  {
    name: "Windows",
    icon: WindowsLogoSVG,
    package_name: "portry.exe",
    download: () => {
      window.open(
        "https://github.com/mololab/portry/raw/master/build/bin/portry.exe"
      );
    },
  },
  {
    name: "Windows (installer)",
    icon: WindowsLogoSVG,
    package_name: "portry-installer.exe",
    download: () => {
      window.open(
        "https://github.com/mololab/portry/raw/master/build/bin/portry-amd64-installer.exe"
      );
    },
  },
  {
    name: "MacOS",
    icon: MacLogoSVG,
    package_name: "portry.dmg",
    download: () => {
      window.open(
        "https://github.com/mololab/portry/raw/master/build/bin/portry.dmg"
      );
    },
  },
  {
    name: "Linux (soon)",
    icon: LinuxLogoSVG,
    package_name: "portry",
    download: () => {
      console.log("download for Linux -> not implemented yet");
    },
  },
];

//

let features = [
  {
    icon: MonitoringSVG,
    name: "Monitoring Table",
    description:
      "Keep track of all your monitored ports in a clear, easy-to-read table format, allowing you to quickly identify and track changes in your network.",
  },
  {
    icon: LiveMonitoringSVG,
    name: "Live Monitoring",
    description:
      "Easily monitor your ports in real-time without the need for manual reloading, giving you peace of mind and the ability to quickly respond to any changes.",
  },
  {
    icon: FilterSVG,
    name: "Filtering",
    description:
      "Streamline your port scanning process with the Filtering feature. Easily filter your results by port interval and table content, making it easier to focus on the ports that matter to you.",
  },
  {
    icon: KillProcessSVG,
    name: "Kill Process",
    description:
      "Take control of your ports with the Kill Process feature. Easily terminate any process running on a specific port, giving you the ability to quickly address any issues and maintain the stability of your network.",
  },
];

export default function Portry() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "Portry" });
  });

  return (
    <>
      <TopNavbar isLanding={false} />
      <div id="home" className="portry-container">
        <div className="portry-promotion">
          <GithubCorner
            className="github-link"
            href={"https://github.com/mololab/portry"}
            bannerColor="#151513"
            octoColor="#fff"
            size={80}
          />

          <div className="portry-line"></div>

          <div className="portry-left">
            <div className="left-content">
              <div className="name">
                <span>portry</span>
                <img src={PortryLogo} alt="portry logo" />
              </div>

              <div className="message">
                <span>Scan your ports like a boss!</span>
              </div>

              <div className="description">
                <span>
                  Easy to use and provides detailed information about the ports
                  on your computer.
                </span>
              </div>

              <div className="action-button">
                <BtnWrapper>
                  <Link to="portry-plans" smooth={true} offset={-80}>
                    <FullButton
                      title="See More"
                      action={undefined}
                      border={undefined}
                    />
                  </Link>
                </BtnWrapper>
              </div>
            </div>
          </div>

          <div className="portry-right">
            <div className="animation-container">
              <img src={ScanAnimation} alt="scan" />
              <div className="magnifying-glass-container">
                <img src={MagnifyingGlass} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div id="portry-plans" className="portry-plans">
          <div className="portry-line"></div>

          <div className="plan-title">
            <span>Plans</span>
          </div>

          <div className="plans">
            <div className="plan-content">
              <div className="plan">
                <div className="header">
                  <img src={FreePlanSVG} alt="" />
                  <span className="plan-type">Basic plan</span>
                  <span className="price">FREE</span>
                </div>

                <div className="body">
                  {freeFeatures.map((feature, i) => (
                    <div className="feature">
                      <img src={CheckSVG} alt="" />
                      <span>{feature}</span>
                    </div>
                  ))}
                </div>

                <div className="footer">
                  <Link
                    to="portry-download"
                    smooth={true}
                    offset={-80}
                    style={{ width: "100%" }}
                  >
                    <div className="start">Get Started</div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="plan-content">
              <div className="warning">
                <span>
                  <span style={{ color: "#697bff" }}>Pro</span> version is not
                  released yet
                </span>
              </div>
              <div className="plan not-released">
                <div className="header">
                  <img src={ProPlanSVG} alt="" />
                  <span className="plan-type">PRO</span>
                  <span className="price">$9/mth</span>
                </div>

                <div className="body">
                  {proFeatures.map((feature, i) => (
                    <div className="feature">
                      <img src={CheckSVG} alt="" />
                      <span>{feature}</span>
                    </div>
                  ))}
                </div>

                <div className="footer">
                  <div className="start">Get Started</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="portry-download" className="portry-download">
          <div className="portry-line"></div>

          <div className="download-title">
            <span>Download</span>
          </div>

          <div className="packages">
            {downloads.map((downloadPackage, i) => (
              <div className="package">
                <div className="header">
                  <img src={downloadPackage.icon} alt="" />
                  <span className="os-name">{downloadPackage.name}</span>
                </div>
                <div className="body">
                  <div
                    className="download"
                    onClick={() => {
                      ReactGA.event({
                        action: "portry_download",
                        category: `${downloadPackage.name.toLowerCase()}_download`,
                        label: `downloaded ${downloadPackage.name.toLowerCase()}`,
                      });

                      downloadPackage.download();
                    }}
                  >
                    Download
                  </div>
                  <span>{downloadPackage.package_name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="portry-features">
          <div className="feature-title">
            <span>Features</span>
          </div>

          <div className="features">
            {features.map((feature, i) => (
              <div className="feature">
                <img src={feature.icon} alt="" />
                <span className="title">{feature.name}</span>
                <span className="description">{feature.description}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="portry-footer">
          <span className="title"> Happy scanning!</span>

          <span className="text">
            Portry is designed to be lightweight and efficient, so it won't slow
            down your computer while scanning. <br /> <br />
            It is perfect tool for network administrators, security
            professionals, and anyone looking to secure their computer. Our tool
            is designed to be intuitive and user-friendly, so even those without
            technical knowledge can use it.
          </span>

          <div
            className="button"
            onClick={() => {
              ReactGA.event({
                action: "portry_footer",
                category: "see_on_github_click",
                label: "clicked to see portry on github",
              });

              window.open("https://github.com/mololab/portry");
            }}
          >
            <span>See on Github</span>
            <img src={GithubLogoSVG} alt="github icon" />
          </div>
        </div>
      </div>

      <HelpUs />
      <Footer />
    </>
  );
}

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
