import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import GolangLogoSVG from "../../assets/svg/technologies/golang-logo.svg";
import TypeScriptSVG from "../../assets/svg/technologies/ts-logo.svg";
import JavaScriptSVG from "../../assets/svg/technologies/js-logo.svg";
import NodeJSSVG from "../../assets/svg/technologies/nodejs-logo.svg";
import HtmlSVG from "../../assets/svg/technologies/html-logo.svg";
import CSSSVG from "../../assets/svg/technologies/css-logo.svg";
import PSQLSVG from "../../assets/svg/technologies/psql-logo.svg";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={GolangLogoSVG} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={TypeScriptSVG} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={JavaScriptSVG} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={NodeJSSVG} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={HtmlSVG} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={CSSSVG} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={PSQLSVG} alt="client logo" />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;
