import React from "react";
import styled from "styled-components";

export default function FullButton({
  title,
  action,
  border,
}: {
  title: any;
  action: any;
  border: any;
}) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : () => {}}
      // border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (true ? "#707070" : "#7620ff")};
  background-color: ${(props) => (true ? "transparent" : "#7620ff")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (true ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (true ? "transparent" : "#580cd2")};
    border: 1px solid #7620ff;
    color: ${(props) => (true ? "#7620ff" : "#fff")};
  }
`;
