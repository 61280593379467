import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import { Link as ExternalLink } from "react-router-dom";
// Components
import Sidebar from "./Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import GithubLogo from "../../assets/svg/GithubLogo";
import GithubStar from "../../assets/svg/GithubStar";
import FullButton from "../Buttons/FullButton";
import { GetAllStars } from "../../service/github/fetcher";

import ReactGA from "react-ga4";

export default function TopNavbar({ isLanding }: { isLanding: boolean }) {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [stars, setStars] = useState(0);

  // GetAllStars
  useEffect(() => {
    GetAllStars().then((star) => {
      setStars(star);
    });
  });

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link to="home" smooth={true}>
            <ExternalLink className="pointer flexNullCenter" to="/">
              <LogoIcon />
              <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                mololab
              </h1>
            </ExternalLink>
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>

          {isLanding && (
            <UlWrapper className="flexNullCenter">
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Home
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="technologies"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Technologies
                </Link>
              </li>
              {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="services"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Services
              </Link>
            </li> */}
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Projects
                </Link>
              </li>
              {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="blog"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Blog
              </Link>
            </li> */}
              {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="pricing"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Pricing
              </Link>
            </li> */}
              {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Contact
              </Link>
            </li> */}
            </UlWrapper>
          )}

          <UlWrapperRight className="flexNullCenter">
            <li
              className="semiBold font15 pointer flexCenter"
              onClick={() => {
                ReactGA.event({
                  action: "header",
                  category: "general_github_click",
                  label:
                    "clicked to see general mololab organization on github",
                });
              }}
            >
              <a
                href="https://github.com/mololab"
                target="_blank"
                className="radius8 lightBg"
                style={{ padding: "10px 20px", display: "flex" }}
                rel="noreferrer"
              >
                <div>
                  <span>Github</span>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <GithubLogo />
                </div>
                {stars !== 0 && (
                  <>
                    <div style={{ marginLeft: "20px" }}>
                      <span>{stars}</span>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                      <GithubStar />
                    </div>
                  </>
                )}
              </a>
            </li>
            {/* <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 0px 10px 30px" }}>
                Log in
              </a>
            </li> */}
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
