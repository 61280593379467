import React from "react";
import logo from "./logo.svg";
import "./App.css";

import { Helmet } from "react-helmet";
import Landing from "./screens/Landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Valoline from "./screens/apps/Valoline";
import ScrollToTop from "./components/Actions/ScrollToTop";
import Portry from "./screens/apps/Portry";
import ReactGA from "react-ga4";
import Jsontt from "./screens/apps/Jsontt";

const GA_MEASUREMENT_ID = "G-VJGKLKZR4G";
ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/valoline" element={<Valoline />} />
          <Route path="/portry" element={<Portry />} />
          <Route path="/jsontt" element={<Jsontt type="github" />} />
          <Route path="/jsontt/npm" element={<Jsontt type="npm" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
