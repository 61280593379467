import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import AddImage2 from "../../assets/img/add/add2.png";

import JsonttImg from "../../assets/img/projects/jsontt-logo.jpg";
import NetstatImg from "../../assets/img/projects/netstat-logo.jpg";
import PortryImg from "../../assets/img/projects/portry_logo.png";

import ValolineImg from "../../assets/img/projects/valoline-logo.png";

import { Link } from "react-router-dom";

import ReactGA from "react-ga4";

export default function Projects() {
  return (
    <Wrapper id="projects" className="mt60">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <Link to="/jsontt">
                <ProjectBox
                  img={JsonttImg}
                  title="jsontt"
                  text="This package & CLI will provide you to translate your JSON file or object into different languages FREE with Google Translate & Libre Translate & Argos Translate API."
                  action={() => {
                    ReactGA.event({
                      action: "landing_projects",
                      category: "jsontt_click",
                      label: "clicked to jsontt on landing",
                    });
                  }}
                />
              </Link>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={NetstatImg}
                title="Netstat Go"
                text="A netstat implementation written in Go [+darwin support implemented by us]"
                action={() => {
                  ReactGA.event({
                    action: "landing_projects",
                    category: "netstat_click",
                    label: "clicked to netstat on landing",
                  });

                  window.open("https://github.com/mololab/netstat");
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <Link to="/valoline">
                <ProjectBox
                  img={ValolineImg}
                  title="Valoline"
                  text="Valoline is a desktop app that provides you to update your game status in the Valorant game 🎮."
                  action={() => {
                    ReactGA.event({
                      action: "landing_projects",
                      category: "valoline_click",
                      label: "clicked to valoline on landing",
                    });
                  }}
                />
              </Link>
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <Link to="/portry">
                <ProjectBox
                  img={PortryImg}
                  title="portry"
                  text="Real-time, simple, and reliable port monitoring tool."
                  action={() => {
                    ReactGA.event({
                      action: "landing_projects",
                      category: "portry_click",
                      label: "clicked to portry on landing",
                    });
                  }}
                />
              </Link>
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton
                title="Load More"
                action={() => {}}
                border={undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
