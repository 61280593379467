import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="30.000000pt"
      height="30.000000pt"
      viewBox="0 0 440.000000 440.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,440.000000) scale(0.100000,-0.100000)"
        fill="#7620FF"
        stroke="none"
      >
        <path
          d="M2755 4252 c-31 -22 -76 -88 -94 -138 -12 -31 -35 -74 -52 -95 -52
-66 -119 -235 -119 -302 0 -17 -5 -19 -37 -13 -100 17 -245 27 -303 21 -168
-17 -336 -70 -471 -149 -88 -52 -235 -186 -276 -253 l-28 -45 -75 6 c-287 24
-394 28 -545 23 -432 -16 -666 -105 -705 -267 -59 -246 403 -605 1170 -911
l145 -58 2 -112 c3 -128 12 -163 60 -236 36 -55 74 -88 140 -121 49 -25 619
-181 680 -186 61 -5 130 10 200 45 70 34 107 74 172 187 39 67 45 73 69 68 62
-14 298 -46 447 -62 222 -24 610 -24 763 -1 615 95 607 422 -20 815 -114 72
-308 174 -449 237 l-106 47 -12 87 c-45 320 -260 610 -566 763 -38 19 -87 41
-107 47 -44 15 -47 28 -23 129 13 55 59 162 69 162 3 0 33 -7 67 -16 141 -36
270 19 356 153 36 57 65 124 39 91 -13 -18 -82 -26 -72 -9 8 12 -28 21 -82 21
-24 0 -41 4 -38 9 7 10 -41 21 -93 21 -24 0 -31 3 -22 9 9 6 -2 11 -33 15 -26
4 -44 11 -41 17 7 11 4 11 -10 1z m-292 -698 c291 -60 545 -291 625 -569 23
-78 37 -175 25 -175 -4 0 -75 19 -159 41 l-152 42 -11 46 c-69 281 -322 522
-616 586 -133 29 -134 31 -24 44 88 11 217 5 312 -15z m-1962 -763 c547 -205
1291 -436 1904 -590 451 -113 1114 -253 1430 -302 111 -17 120 -24 49 -40 -43
-10 -144 -14 -379 -14 -335 1 -428 8 -770 66 -726 122 -1557 405 -2043 696
-156 94 -329 223 -298 223 2 0 50 -18 107 -39z"
        />
        <path
          d="M1598 1345 l-88 -55 -62 16 c-100 26 -208 15 -300 -29 -124 -60 -242
-200 -323 -385 -10 -23 -15 -45 -12 -49 4 -3 67 -8 141 -11 220 -7 373 42 472
151 47 52 102 147 85 147 -9 0 -180 -34 -231 -46 -52 -12 1 19 227 130 127 63
234 119 237 125 7 11 -36 61 -51 61 -4 0 -47 -25 -95 -55z"
        />
        <path
          d="M1842 1289 c-7 -24 -22 -91 -35 -149 -21 -98 -24 -106 -53 -121 -150
-74 -246 -186 -296 -339 -25 -80 -28 -249 -5 -356 15 -66 80 -260 91 -272 10
-9 87 51 172 135 255 251 311 528 157 777 l-36 59 47 145 c26 79 44 147 41
150 -3 3 -5 0 -5 -6 0 -18 -41 -14 -55 4 -10 14 -13 10 -23 -27z"
        />
        <path
          d="M2063 1288 c-18 -4 -34 -12 -37 -17 -3 -5 62 -111 145 -236 82 -125
148 -229 146 -231 -2 -2 -46 34 -98 80 -52 46 -97 82 -101 79 -5 -2 -8 -32 -8
-67 0 -200 134 -370 387 -488 47 -22 87 -37 90 -34 14 13 22 196 13 276 -16
140 -50 225 -125 308 -56 62 -116 99 -205 128 l-65 21 -45 86 c-25 48 -50 91
-55 95 -6 5 -24 6 -42 0z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
