import axios from "axios";

let originalRepoURLs: string[] = [
  "https://github.com/mololab/netstat",
  "https://github.com/mololab/json-translator",
  "https://github.com/ParvinEyvazov/valoline",
  "https://github.com/mololab/portry",
];

let githubUrl = "https://github.com";
let githubAPIUrl = "https://api.github.com/repos";

export async function GetAllStars(): Promise<number> {
  let numberOfStars = 0;
  const repoURLs = convertAPIRepoUrls(originalRepoURLs);

  for (const repoURL of repoURLs) {
    await axios.get(repoURL).then((data) => {
      numberOfStars = numberOfStars + Number(data.data["stargazers_count"]);
    });
  }

  return numberOfStars;
}

function convertAPIRepoUrls(repos: string[]): string[] {
  let apiRepos = repos.map((repo) => {
    return repo.replace(githubUrl, githubAPIUrl);
  });

  return apiRepos;
}
