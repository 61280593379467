import React, { useEffect } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Technologies from "../components/Sections/Technologies";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import HelpUs from "../components/Sections/HelpUs";
import ReactGA from "react-ga4";

export default function Landing() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "Landing" });
  });

  return (
    <>
      <TopNavbar isLanding={true} />
      <Header />
      <Technologies />
      {/* <Services /> */}
      <Projects />
      <HelpUs />
      {/* <Blog />
      <Pricing />
      <Contact /> */}
      <Footer />
    </>
  );
}
