import React from "react";
import styled from "styled-components";

export default function ProjectBox({
  img,
  title,
  text,
  action,
}: {
  img: any;
  title: any;
  text: any;
  action: any;
}) {
  return (
    <Wrapper>
      <ImgBtn
        className="aniamte pointer"
        onClick={action ? () => action() : () => {}}
      >
        <img className="radius8" src={img} alt="project"></img>
      </ImgBtn>
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13">{text}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 375px;
    height: 300px;
    object-fit: cover;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }
`;
