import { useEffect } from "react";
import JsonttImg from "../../assets/img/projects/jsontt-logo.jpg";

let redirect_link_types = {
  github: "https://github.com/mololab/json-translator",
  npm: "https://www.npmjs.com/package/@parvineyvazov/json-translator",
};

export default function Jsontt({ type }: { type: string }) {
  useEffect(() => {
    setTimeout(() => {
      let link = redirect_link_types[type as "github" | "npm"];

      window.location.replace(link);
    }, 300);
  }, []);

  return (
    <>
      <div className="jsontt-container">
        <div className="logo-container">
          <img src={JsonttImg} alt="" />
        </div>
      </div>
    </>
  );
}
