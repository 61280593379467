import { useEffect } from "react";
import TopNavbar from "../../components/Nav/TopNavbar";
import Footer from "../../components/Sections/Footer";
import ReactGA from "react-ga4";

export default function Valoline() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "Valoline" });
  });

  return (
    <>
      <TopNavbar isLanding={false} />

      <div
        className="valoline-frame"
        onClick={() => {
          ReactGA.event({
            action: "valoline_download",
            category: `valoline_download`,
            label: `downloaded valoline`,
          });
        }}
      >
        <iframe title="valoline" src="https://valoline.web.app/"></iframe>
      </div>

      <Footer />
    </>
  );
}
